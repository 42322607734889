let interval: ReturnType<typeof setInterval>;

export function openGleenAI(input?: string) {
  const gleenContainer = document.getElementById('helix-chat-container');
  gleenContainer?.classList.add('d-block');

  if (input) {
    interval = setInterval(() => {
      const form = gleenContainer?.querySelector('form');
      const submit = form?.querySelector('button');
      const textarea = form?.querySelector('textarea');

      if (textarea) {
        const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor?.(window.HTMLTextAreaElement.prototype, 'value')?.set;
        nativeTextAreaValueSetter?.call(textarea, input);

        const event = new Event('input', { bubbles: true });
        textarea.dispatchEvent(event);
        submit?.click();

        clearInterval(interval);
      }
    }, 1000);
  }

  window.gleenWidget?.open();
}
