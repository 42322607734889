import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AppConfigType, Result } from '@types';

export function useAppConfig() {
  return useQuery({
    queryKey: ['/app/config'],
    queryFn: (): Promise<Result<AppConfigType>> => axios.get('/app/config')
  });
}
